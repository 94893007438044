import icon1 from '../image/kcell-5G.png'
import icon2 from '../image/kcell-bc.png'
import icon3 from '../image/kar-tel-bc-new.png'
import icon4 from '../image/kcell-new-locate.png'
import icon5 from '../image/wi-fi.png'
import iconRed from '../image/amc-red.png'
import iconYellow from '../image/amc-yellow.png'
import iconGreen from '../image/amc-green.png'
import iconRedBee from '../image/amc-red-bee.png'
import iconYellowBee from '../image/amc-yellow-bee.png'
import iconGreenBee from '../image/amc-green-bee.png'

export const operators = [

    // Kcell 5G **************************************************************************************************************************
    {
      id: 1,
      name: 'Operator 1',
      points: [
        { id: 1, name: 'Point 13', coordinates: [42.332310, 69.566052] },
        { id: 2, name: 'Point 13', coordinates: [42.309875, 69.607247] },
        { id: 3, name: 'Point 13', coordinates: [42.309349, 69.584078] },
        { id: 4, name: 'Point 13', coordinates: [42.299256161, 69.595776234] },
        { id: 5, name: 'Point 13', coordinates: [42.326028, 69.626832] },
        { id: 6, name: 'Point 13', coordinates: [42.316080, 69.586280] },
        { id: 7, name: 'Point 13', coordinates: [42.350131, 69.569581] },
        { id: 8, name: 'Point 13', coordinates: [42.319917, 69.594928] },
        { id: 9, name: 'Point 13', coordinates: [42.334000, 69.631100] },
        { id: 10, name: 'Point 13', coordinates: [42.291545137, 69.600695957] },
        { id: 11, name: 'Point 13', coordinates: [42.305688889,	69.625091667] },
        { id: 12, name: 'Point 13', coordinates: [42.301712727,	69.578596507] },
        { id: 284, name: 'Point 13', coordinates: [42.342391667, 69.628730556] },
        { id: 285, name: 'Point 13', coordinates: [42.305695,	69.57649694] },
        { id: 286, name: 'Point 13', coordinates: [42.312883333,	69.598975] },
        { id: 287, name: 'Point 13', coordinates: [42.339467, 69.524522] },
        { id: 288, name: 'Point 13', coordinates: [42.324114, 69.563582] },
        { id: 289, name: 'Point 13', coordinates: [42.298614, 69.617000] },
        { id: 290, name: 'Point 13', coordinates: [42.346586, 69.611491] },
        { id: 291, name: 'Point 13', coordinates: [42.342222, 69.602278] },
        { id: 292, name: 'Point 13', coordinates: [42.322350, 69.635436] },
        { id: 293, name: 'Point 13', coordinates: [42.330833, 69.540000] },
        { id: 294, name: 'Point 13', coordinates: [42.354127, 69.613827] },
        { id: 295, name: 'Point 13', coordinates: [42.317583, 69.604358] },
        { id: 296, name: 'Point 13', coordinates: [42.335694, 69.561556] },
        { id: 297, name: 'Point 13', coordinates: [42.298677, 69.609892] },
        { id: 298, name: 'Point 13', coordinates: [42.283903, 69.566994] },
        { id: 299, name: 'Point 13', coordinates: [42.315278, 69.556944] },
        { id: 300, name: 'Point 13', coordinates: [42.335008, 69.592497] },
        { id: 301, name: 'Point 13', coordinates: [42.339294, 69.639904] },
        { id: 302, name: 'Point 13', coordinates: [42.310558, 69.600325] },
        { id: 303, name: 'Point 13', coordinates: [42.318502, 69.578168] },
        { id: 304, name: 'Point 13', coordinates: [42.342076, 69.648177] },
        { id: 305, name: 'Point 13', coordinates: [42.350308, 69.534361] },
        { id: 306, name: 'Point 13', coordinates: [42.426908, 69.625803] },
        { id: 307, name: 'Point 13', coordinates: [42.426908, 69.625803] },
        { id: 308, name: 'Point 13', coordinates: [42.363052, 69.703598] },
        { id: 309, name: 'Point 13', coordinates: [42.305167, 69.638583] },
        { id: 310, name: 'Point 13', coordinates: [42.314853, 69.597997] },
        { id: 311, name: 'Point 13', coordinates: [42.325669, 69.576439] },
        { id: 312, name: 'Point 13', coordinates: [42.333600, 69.603492] },
        { id: 313, name: 'Point 13', coordinates: [42.359553, 69.539308] },
        { id: 314, name: 'Point 13', coordinates: [42.387219, 69.626316] },
        { id: 315, name: 'Point 13', coordinates: [42.341440, 69.567039] },
        { id: 316, name: 'Point 13', coordinates: [42.386398, 69.514097] },
        { id: 317, name: 'Point 13', coordinates: [42.359472, 69.559944] },
        { id: 318, name: 'Point 13', coordinates: [42.314842, 69.570308] },
        { id: 319, name: 'Point 13', coordinates: [42.282056, 69.604528] },
        { id: 320, name: 'Point 13', coordinates: [42.291028, 69.629278] },
        { id: 321, name: 'Point 13', coordinates: [42.318250, 69.599000] },
        { id: 322, name: 'Point 13', coordinates: [42.359139, 69.635000] },
        { id: 323, name: 'Point 13', coordinates: [42.351431, 69.551139] },
        { id: 324, name: 'Point 13', coordinates: [42.343180, 69.579880] },
        { id: 325, name: 'Point 13', coordinates: [42.336553, 69.656100] },
        { id: 326, name: 'Point 13', coordinates: [42.284344, 69.669687] },
        { id: 327, name: 'Point 13', coordinates: [42.350880, 69.471950] },
        { id: 328, name: 'Point 13', coordinates: [42.376160, 69.725905] },
        { id: 329, name: 'Point 13', coordinates: [42.336753, 69.651208] },
        { id: 330, name: 'Point 13', coordinates: [42.355308, 69.652238] },
        { id: 331, name: 'Point 13', coordinates: [42.373944, 69.633583] },
        { id: 751, name: 'Point 13', coordinates: [42.339539, 69.601267] },
        { id: 752, name: 'Point 13', coordinates: [42.430389, 69.644278] },
        { id: 753, name: 'Point 13', coordinates: [42.377653, 69.541431] },
        { id: 754, name: 'Point 13', coordinates: [42.320542, 69.586353] },
        { id: 755, name: 'Point 13', coordinates: [42.366744, 69.584857] },
        { id: 756, name: 'Point 13', coordinates: [42.342314, 69.642672] },
        { id: 757, name: 'Point 13', coordinates: [42.383306, 69.653389] },
        { id: 758, name: 'Point 13', coordinates: [42.311306, 69.585861] },
        { id: 759, name: 'Point 13', coordinates: [42.341981, 69.613431] },
        { id: 760, name: 'Point 13', coordinates: [42.333973, 69.599658] },
        { id: 761, name: 'Point 13', coordinates: [42.378726, 69.586935] },
        { id: 762, name: 'Point 13', coordinates: [42.359003, 69.641784] },
        { id: 763, name: 'Point 13', coordinates: [42.383568, 69.640627] },
        { id: 764, name: 'Point 13', coordinates: [42.368844, 69.644239] },
        { id: 765, name: 'Point 13', coordinates: [42.354361, 69.597750] },
        { id: 766, name: 'Point 13', coordinates: [42.367917, 69.597889] },
        { id: 767, name: 'Point 13', coordinates: [42.351583, 69.573639] },
        { id: 768, name: 'Point 13', coordinates: [42.325453, 69.554942] },
        { id: 769, name: 'Point 13', coordinates: [42.395956, 69.491269] },
        { id: 770, name: 'Point 13', coordinates: [42.305833, 69.537000] },
        { id: 771, name: 'Point 13', coordinates: [42.404944, 69.635500] },
        { id: 772, name: 'Point 13', coordinates: [42.362532, 69.648520] },
        { id: 773, name: 'Point 13', coordinates: [42.350944, 69.677750] },
        { id: 774, name: 'Point 13', coordinates: [42.351500, 69.648833] },
        { id: 775, name: 'Point 13', coordinates: [42.327889, 69.612833] },
        { id: 776, name: 'Point 13', coordinates: [42.348640, 69.517426] },
        { id: 777, name: 'Point 13', coordinates: [42.325136, 69.596530] },
        { id: 778, name: 'Point 13', coordinates: [42.342044, 69.608163] },
        { id: 779, name: 'Point 13', coordinates: [42.334797, 69.625450] },
        { id: 780, name: 'Point 13', coordinates: [42.340014, 69.553919] },
        { id: 781, name: 'Point 13', coordinates: [42.392231, 69.656997] },
        { id: 782, name: 'Point 13', coordinates: [42.355625, 69.662550] },
        { id: 783, name: 'Point 13', coordinates: [42.376351, 69.593995] },
        { id: 784, name: 'Point 13', coordinates: [42.416789, 69.568703] },
        { id: 785, name: 'Point 13', coordinates: [42.363458, 69.666028] },
        { id: 786, name: 'Point 13', coordinates: [42.376999, 69.603347] },
        { id: 787, name: 'Point 13', coordinates: [42.420441, 69.578543] },
        { id: 788, name: 'Point 13', coordinates: [42.402628, 69.544102] },
        { id: 789, name: 'Point 13', coordinates: [42.360745, 69.568872] },
        { id: 790, name: 'Point 13', coordinates: [42.317929, 69.597399] },
        { id: 791, name: 'Point 13', coordinates: [42.343000, 69.677722] },
        { id: 792, name: 'Point 13', coordinates: [42.371167, 69.504222] },
        { id: 793, name: 'Point 13', coordinates: [42.347167, 69.656667] },
        
        { id: 794, name: 'Point 13', coordinates: [42.284765, 69.643568] },
        { id: 795, name: 'Point 13', coordinates: [42.315537, 69.579525] },
        { id: 796, name: 'Point 13', coordinates: [42.307692,	69.566534] },
        { id: 797, name: 'Point 13', coordinates: [42.32941,	69.606403] },
        { id: 798, name: 'Point 13', coordinates: [42.338515,	69.554503] },
        { id: 799, name: 'Point 13', coordinates: [42.289228,	69.545978] },
        { id: 800, name: 'Point 13', coordinates: [42.369434,	69.669853] },
        { id: 801, name: 'Point 13', coordinates: [42.308024,	69.583591] },
        { id: 802, name: 'Point 13', coordinates: [42.323412, 69.633764] },
        { id: 803, name: 'Point 13', coordinates: [42.460072, 69.548568] },
        { id: 804, name: 'Point 13', coordinates: [42.311157, 69.6272  ] },
        { id: 805, name: 'Point 13', coordinates: [42.343749, 69.686504] },
        { id: 806, name: 'Point 13', coordinates: [42.280174,	69.614824] },
        { id: 807, name: 'Point 13', coordinates: [42.358035,	69.428189] },
        { id: 808, name: 'Point 13', coordinates: [42.357511,	69.609165] },
        { id: 809, name: 'Point 13', coordinates: [42.395728,	69.422045] },
        { id: 810, name: 'Point 13', coordinates: [42.36587, 	69.495933] },
        { id: 811, name: 'Point 13', coordinates: [42.292386,	69.519054] },
        { id: 812, name: 'Point 13', coordinates: [42.322763,	69.588197] },
        { id: 813, name: 'Point 13', coordinates: [42.358749,	69.62769 ] },
        { id: 814, name: 'Point 13', coordinates: [42.270431,	69.84172 ] },
        { id: 815, name: 'Point 13', coordinates: [42.374276,	69.494688] },
        { id: 816, name: 'Point 13', coordinates: [42.299425,	69.759726] },
        { id: 817, name: 'Point 13', coordinates: [42.398671,	69.649555] },
        { id: 818, name: 'Point 13', coordinates: [42.407283,	69.600653] },
        { id: 819, name: 'Point 13', coordinates: [42.407664,	69.625664] },
        { id: 820, name: 'Point 13', coordinates: [42.320406,	69.607667] },
        { id: 821, name: 'Point 13', coordinates: [42.345475,	69.639404] },
        { id: 822, name: 'Point 13', coordinates: [42.389469,	69.598383] },
        { id: 823, name: 'Point 13', coordinates: [42.462758,	69.532033] },
        { id: 824, name: 'Point 13', coordinates: [42.309109, 69.63372 ] },
        { id: 825, name: 'Point 13', coordinates: [42.320013,	69.584917] },
        { id: 826, name: 'Point 13', coordinates: [42.369259,	69.52986 ] },
        { id: 827, name: 'Point 13', coordinates: [42.355875, 69.647272] },
        { id: 828, name: 'Point 13', coordinates: [42.324793, 69.586183] },
        { id: 829, name: 'Point 13', coordinates: [42.365445,	69.628706] },
        { id: 830, name: 'Point 13', coordinates: [42.329485, 69.594374] },
        { id: 831, name: 'Point 13', coordinates: [42.35753, 	69.533777] },
        { id: 832, name: 'Point 13', coordinates: [42.32531, 	69.591554] },
        { id: 833, name: 'Point 13', coordinates: [42.199354, 69.806146] },
        { id: 834, name: 'Point 13', coordinates: [42.324417,	69.580748] },
        { id: 835, name: 'Point 13', coordinates: [42.291567,	69.51164 ] },
        { id: 836, name: 'Point 13', coordinates: [42.316673,	69.589521] },
        { id: 837, name: 'Point 13', coordinates: [42.314146, 69.60245 ] },
        { id: 838, name: 'Point 13', coordinates: [42.300989,	69.607384] },
        { id: 839, name: 'Point 13', coordinates: [42.332523, 69.581532] },
        { id: 840, name: 'Point 13', coordinates: [42.288925, 69.603149] },
        { id: 841, name: 'Point 13', coordinates: [42.293439, 69.768543] },
        { id: 842, name: 'Point 13', coordinates: [42.359027,	69.582174] },
        { id: 843, name: 'Point 13', coordinates: [42.251718,	69.679113] },
        { id: 844, name: 'Point 13', coordinates: [42.323561,	69.618163] },
        { id: 845, name: 'Point 13', coordinates: [42.342074,	69.567248] },
        { id: 846, name: 'Point 13', coordinates: [42.325781, 69.621036] },
        { id: 847, name: 'Point 13', coordinates: [42.398511,	69.656233] },
        { id: 848, name: 'Point 13', coordinates: [42.320892,	69.628922] },
        { id: 849, name: 'Point 13', coordinates: [42.313123, 69.585525] },
        { id: 850, name: 'Point 13', coordinates: [42.328955, 69.56704 ] },
        { id: 851, name: 'Point 13', coordinates: [42.34966, 	69.558609] },
        { id: 852, name: 'Point 13', coordinates: [42.331203,	69.612008] },
        { id: 853, name: 'Point 13', coordinates: [42.411164,	69.635438] },
        { id: 854, name: 'Point 13', coordinates: [42.359611,	69.642654] },
        { id: 855, name: 'Point 13', coordinates: [42.363006, 69.703254] },
        { id: 856, name: 'Point 13', coordinates: [42.31647,	69.636142] },
        { id: 857, name: 'Point 13', coordinates: [42.28413, 	69.670937] },
        { id: 858, name: 'Point 13', coordinates: [42.293493,	69.770724] },
        { id: 859, name: 'Point 13', coordinates: [42.334635,	69.572418] },
        { id: 860, name: 'Point 13', coordinates: [42.296702,	69.648639] },
        { id: 861, name: 'Point 13', coordinates: [42.212903, 69.79897 ] },
        { id: 862, name: 'Point 13', coordinates: [42.289518,	69.667542] },
        { id: 863, name: 'Point 13', coordinates: [42.318129,	69.645583] },
        { id: 864, name: 'Point 13', coordinates: [42.345589,	69.613727] },
        // Другие точки оператора 1...

        // Kcell БС / 4G *******************************************************************************************************
      ],
      pointIcon: icon1// Замените на путь к иконке точки оператора 1
    },
    {
      id: 2,
      name: 'Operator 2',
      points: [
        { id: 13, name: 'Point 13', coordinates: [42.376083, 69.554333] },
        { id: 14, name: 'Point 13', coordinates: [42.4282219, 69.6125166] },
        { id: 15, name: 'Point 13', coordinates: [42.2623344, 69.5790533] },
        { id: 15, name: 'Point 13', coordinates: [42.405550, 69.5790533] },
        { id: 17, name: 'Point 13', coordinates: [42.2400406, 69.7812725] },
        { id: 18, name: 'Point 13', coordinates: [42.4230182, 69.6360725] },
        { id: 19, name: 'Point 13', coordinates: [42.4344719, 69.529811] },
        { id: 20, name: 'Point 13', coordinates: [42.4367813, 69.5504505] },
        { id: 21, name: 'Point 13', coordinates: [42.37305, 69.4793552] },
        { id: 22, name: 'Point 13', coordinates: [42.178295, 69.843386] },
        { id: 23, name: 'Point 13', coordinates: [42.310895, 69.723192] },
        { id: 24, name: 'Point 13', coordinates: [42.370109, 69.659732] },
        { id: 25, name: 'Point 13', coordinates: [42.379244, 69.642764] },
        { id: 26, name: 'Point 13', coordinates: [42.338795, 69.50116] },
        { id: 27, name: 'Point 13', coordinates: [42.309761, 69.580477] },
        { id: 28, name: 'Point 13', coordinates: [42.301079, 69.600517] },
        { id: 39, name: 'Point 13', coordinates: [42.300727, 69.593221] },
        { id: 30, name: 'Point 13', coordinates: [42.32646, 69.631807] },
        { id: 31, name: 'Point 13', coordinates: [42.29891, 69.768779] },
        { id: 32, name: 'Point 13', coordinates: [42.293302, 69.76432] },
        { id: 33, name: 'Point 13', coordinates: [42.279493, 69.544796] },
        { id: 34, name: 'Point 13', coordinates: [42.313234, 69.584524] },
        { id: 35, name: 'Point 13', coordinates: [42.316581, 69.58279] },
        { id: 36, name: 'Point 13', coordinates: [42.373525, 69.489543] },
        { id: 37, name: 'Point 13', coordinates: [42.354419, 69.50762] },
        { id: 38, name: 'Point 13', coordinates: [42.3538284, 69.5140305] },
        { id: 39, name: 'Point 13', coordinates: [42.328125, 69.664089] },
        { id: 40, name: 'Point 13', coordinates: [42.350019, 69.572325] },
        { id: 41, name: 'Point 13', coordinates: [42.350662, 69.562431] },
        { id: 42, name: 'Point 13', coordinates: [42.27101, 69.714046] },
        { id: 43, name: 'Point 13', coordinates: [42.335995, 69.632831] },
        { id: 44, name: 'Point 13', coordinates: [42.330665, 69.631368] },
        { id: 45, name: 'Point 13', coordinates: [42.325235, 69.579978] },
        { id: 46, name: 'Point 13', coordinates: [42.308272, 69.625427] },
        { id: 47, name: 'Point 13', coordinates: [42.338573, 69.628427] },
        { id: 48, name: 'Point 13', coordinates: [42.335379, 69.529644] },
        { id: 49, name: 'Point 13', coordinates: [42.353715, 69.686195] },
        { id: 50, name: 'Point 13', coordinates: [42.320038, 69.579029] },
        { id: 51, name: 'Point 13', coordinates: [42.34468, 69.610354] },
        { id: 52, name: 'Point 13', coordinates: [42.319038, 69.631473] },
        { id: 53, name: 'Point 13', coordinates: [42.344111, 69.642329] },
        { id: 54, name: 'Point 13', coordinates: [42.349937, 69.635264] },
        { id: 55, name: 'Point 13', coordinates: [42.35486, 69.604504] },
        { id: 56, name: 'Point 13', coordinates: [42.315373, 69.605296] },
        { id: 57, name: 'Point 13', coordinates: [42.314864, 69.627226] },
        { id: 58, name: 'Point 13', coordinates: [42.297666, 69.613232] },
        { id: 59, name: 'Point 13', coordinates: [42.438546, 69.558393] },
        { id: 60, name: 'Point 13', coordinates: [42.430176, 69.550498] },
        { id: 61, name: 'Point 13', coordinates: [42.424699, 69.563137] },
        { id: 62, name: 'Point 13', coordinates: [42.278668, 69.564294] },
        { id: 63, name: 'Point 13', coordinates: [42.318905, 69.55747] },
        { id: 64, name: 'Point 13', coordinates: [42.332185, 69.596182] },
        { id: 65, name: 'Point 13', coordinates: [42.342396, 69.621256] },
        { id: 66, name: 'Point 13', coordinates: [42.337404, 69.640793] },
        { id: 67, name: 'Point 13', coordinates: [42.354027, 69.537616] },
        { id: 68, name: 'Point 13', coordinates: [42.378751, 69.712905] },
        { id: 69, name: 'Point 13', coordinates: [42.373272, 69.690246] },
        { id: 70, name: 'Point 13', coordinates: [42.383134, 69.69442] },
        { id: 71, name: 'Point 13', coordinates: [42.321074, 69.609587] },
        { id: 72, name: 'Point 13', coordinates: [42.303595, 69.631066] },
        { id: 73, name: 'Point 13', coordinates: [42.339507, 69.539061] },
        { id: 74, name: 'Point 13', coordinates: [42.336439, 69.600084] },
        { id: 75, name: 'Point 13', coordinates: [42.363197, 69.541569] },
        { id: 76, name: 'Point 13', coordinates: [42.362865, 69.532589] },
        { id: 77, name: 'Point 13', coordinates: [42.29657, 69.557996] },
        { id: 78, name: 'Point 13', coordinates: [42.391995, 69.633752] },
        { id: 79, name: 'Point 13', coordinates: [42.383537, 69.633182] },
        { id: 80, name: 'Point 13', coordinates: [42.341686, 69.573455] },
        { id: 81, name: 'Point 13', coordinates: [42.355146, 69.570944] },
        { id: 82, name: 'Point 13', coordinates: [42.359689, 69.623068] },
        { id: 83, name: 'Point 13', coordinates: [42.254433, 69.632165] },
        { id: 84, name: 'Point 13', coordinates: [42.365186, 69.563293] },
        { id: 85, name: 'Point 13', coordinates: [42.356162, 69.554676] },
        { id: 86, name: 'Point 13', coordinates: [42.315508, 69.574325] },
        { id: 87, name: 'Point 13', coordinates: [42.277316, 69.59442] },
        { id: 88, name: 'Point 13', coordinates: [42.327908, 69.567919] },
        { id: 89, name: 'Point 13', coordinates: [42.320721, 69.542564] },
        { id: 90, name: 'Point 13', coordinates: [42.286338, 69.635481] },
        { id: 91, name: 'Point 13', coordinates: [42.358267, 69.705347] },
        { id: 92, name: 'Point 13', coordinates: [42.32991, 69.531743] },
        { id: 93, name: 'Point 13', coordinates: [42.33381, 69.517282] },
        { id: 94, name: 'Point 13', coordinates: [42.392466, 69.569813] },
        { id: 95, name: 'Point 13', coordinates: [42.39614, 69.552282] },
        { id: 96, name: 'Point 13', coordinates: [42.43922, 69.822675] },
        { id: 97, name: 'Point 13', coordinates: [42.434382, 69.825305] },
        { id: 98, name: 'Point 13', coordinates: [42.431926, 69.819492] },
        { id: 99, name: 'Point 13', coordinates: [42.289947, 69.673677] },
        { id: 100, name: 'Point 13', coordinates: [42.280151, 69.679215] },
        { id: 101, name: 'Point 13', coordinates: [42.287107, 69.663059] },
        { id: 102, name: 'Point 13', coordinates: [42.35611, 69.592112] },
        { id: 103, name: 'Point 13', coordinates: [42.350704, 69.46495] },
        { id: 104, name: 'Point 13', coordinates: [42.449468, 69.528249] },
        { id: 105, name: 'Point 13', coordinates: [42.354346, 69.65724] },
        { id: 106, name: 'Point 13', coordinates: [42.411379, 69.56253] },
        { id: 107, name: 'Point 13', coordinates: [42.412569, 69.605811] },
        { id: 108, name: 'Point 13', coordinates: [42.406414, 69.592334] },
        { id: 109, name: 'Point 13', coordinates: [42.410836, 69.582139] },
        { id: 110, name: 'Point 13', coordinates: [42.391411, 69.583467] },
        { id: 111, name: 'Point 13', coordinates: [42.333866, 69.662891] },
        { id: 112, name: 'Point 13', coordinates: [42.378911, 69.509555] },
        { id: 113, name: 'Point 13', coordinates: [42.38301, 69.492628] },
        { id: 114, name: 'Point 13', coordinates: [42.389555, 69.497784] },
        { id: 115, name: 'Point 13', coordinates: [42.273964, 69.627662] },
        { id: 116, name: 'Point 13', coordinates: [42.264435, 69.628247] },
        { id: 117, name: 'Point 13', coordinates: [42.371995, 69.547045] },
        { id: 118, name: 'Point 13', coordinates: [42.374793, 69.66681] },
        { id: 119, name: 'Point 13', coordinates: [42.379222, 69.651821] },
        { id: 120, name: 'Point 13', coordinates: [42.285631, 69.784682] },
        { id: 121, name: 'Point 13', coordinates: [42.341556, 69.59725] },
        { id: 122, name: 'Point 13', coordinates: [42.290319, 69.514346] },
        { id: 123, name: 'Point 13', coordinates: [42.429122, 69.635391] },
        { id: 124, name: 'Point 13', coordinates: [42.383426, 69.549151] },
        { id: 125, name: 'Point 13', coordinates: [42.3715006, 69.577677] },
        { id: 126, name: 'Point 13', coordinates: [42.274155, 69.57459] },
        { id: 127, name: 'Point 13', coordinates: [42.307361, 69.577229] },
        { id: 128, name: 'Point 13', coordinates: [42.386623, 69.646502] },

        { id: 129, name: 'Point 13', coordinates: [42.425051, 69.830723] },

        { id: 130, name: 'Point 13', coordinates: [42.41912, 69.839389] },

        { id: 131, name: 'Point 13', coordinates: [42.415104, 69.823472] },

        { id: 132, name: 'Point 13', coordinates: [42.351135, 69.58313] },

        { id: 133, name: 'Point 13', coordinates: [42.359684, 69.527572] },

        { id: 134, name: 'Point 13', coordinates: [42.366139, 69.6957] },

        { id: 135, name: 'Point 13', coordinates: [42.3971492, 69.6114991] },

        { id: 136, name: 'Point 13', coordinates: [42.400231, 69.586986] },

        { id: 137, name: 'Point 13', coordinates: [42.378947, 69.596439] },

        { id: 138, name: 'Point 13', coordinates: [42.372795, 69.591329] },

        { id: 139, name: 'Point 13', coordinates: [42.356241, 69.645466] },

        { id: 140, name: 'Point 13', coordinates: [42.354865, 69.642466] },

        { id: 141, name: 'Point 13', coordinates: [42.359484, 69.676012] },

        { id: 142, name: 'Point 13', coordinates: [42.393051, 69.643317] },

        { id: 143, name: 'Point 13', coordinates: [42.381468, 69.629918] },

        { id: 144, name: 'Point 13', coordinates: [42.367756, 69.649597] },

        { id: 145, name: 'Point 13', coordinates: [42.367734, 69.603302] },

        { id: 146, name: 'Point 13', coordinates: [42.309299, 69.513778] },

        { id: 147, name: 'Point 13', coordinates: [42.325115, 69.559319] },

        { id: 148, name: 'Point 13', coordinates: [42.3820084, 69.5296831] },

        { id: 149, name: 'Point 13', coordinates: [42.307393, 69.524477] },

        { id: 150, name: 'Point 13', coordinates: [42.398183, 69.645692] },

        { id: 151, name: 'Point 13', coordinates: [42.362414, 69.655482] },

        { id: 152, name: 'Point 13', coordinates: [42.360198, 69.648469] },

        { id: 153, name: 'Point 13', coordinates: [42.348202, 69.668442] },

        { id: 154, name: 'Point 13', coordinates: [42.364312, 69.677663] },

        { id: 155, name: 'Point 13', coordinates: [42.362776, 69.583312] },

        { id: 156, name: 'Point 13', coordinates: [42.352389, 69.657176] },

        { id: 157, name: 'Point 13', coordinates: [42.347374, 69.647612] },

        { id: 158, name: 'Point 13', coordinates: [42.280517, 69.6254] },

        { id: 159, name: 'Point 13', coordinates: [42.360043, 69.630299] },

        { id: 160, name: 'Point 13', coordinates: [42.318967, 69.662786] },

        { id: 161, name: 'Point 13', coordinates: [42.358254, 69.450216] },

        { id: 162, name: 'Point 13', coordinates: [42.326731, 69.603384] },

        { id: 163, name: 'Point 13', coordinates: [42.3957061, 69.5785098] },

        { id: 164, name: 'Point 13', coordinates: [42.325224, 69.684212] },

        { id: 165, name: 'Point 13', coordinates: [42.459501, 69.545805] },

        { id: 166, name: 'Point 13', coordinates: [42.457739, 69.51425] },

        { id: 167, name: 'Point 13', coordinates: [42.335141, 69.628173] },

        { id: 168, name: 'Point 13', coordinates: [42.341849, 69.548743] },

        { id: 169, name: 'Point 13', coordinates: [42.369175, 69.664945] },

        { id: 170, name: 'Point 13', coordinates: [42.377947, 69.596974] },

        { id: 171, name: 'Point 13', coordinates: [42.79949, 69.597481] },

        { id: 172, name: 'Point 13', coordinates: [42.3666621, 69.5114795] },

        { id: 173, name: 'Point 13', coordinates: [42.359756, 69.657805] },

        { id: 174, name: 'Point 13', coordinates: [42.409328, 69.614712] },

        { id: 175, name: 'Point 13', coordinates: [42.41024, 69.634774] },

        { id: 176, name: 'Point 13', coordinates: [42.403714, 69.614321] },
        
        { id: 177, name: 'Point 13', coordinates: [42.400276, 69.555369] },

        { id: 178, name: 'Point 13', coordinates: [42.410418, 69.532136] },

        { id: 179, name: 'Point 13', coordinates: [42.400282, 69.857858] },

        { id: 180, name: 'Point 13', coordinates: [42.387263, 69.84226] },

        { id: 181, name: 'Point 13', coordinates: [42.412349, 69.799716] },

        { id: 182, name: 'Point 13', coordinates: [42.552407, 69.656352] },

        { id: 183, name: 'Point 13', coordinates: [42.529648, 69.668102] },

        { id: 184, name: 'Point 13', coordinates: [42.470093, 69.653953] },

        { id: 185, name: 'Point 13', coordinates: [42.466139, 69.624668] },

        { id: 186, name: 'Point 13', coordinates: [42.482248, 69.631673] },

        { id: 187, name: 'Point 13', coordinates: [42.529128, 69.778659] },

        { id: 188, name: 'Point 13', coordinates: [42.20786, 69.804281] },

        { id: 189, name: 'Point 13', coordinates: [42.381907, 69.909029] },

        { id: 190, name: 'Point 13', coordinates: [42.385388, 69.875738] },

        { id: 191, name: 'Point 13', coordinates: [42.324948, 69.777045] },

        { id: 192, name: 'Point 13', coordinates: [42.341651, 69.685848] },

        { id: 193, name: 'Point 13', coordinates: [42.337156, 69.675597] },

        { id: 194, name: 'Point 13', coordinates: [42.171939, 69.871284] },

        { id: 195, name: 'Point 13', coordinates: [42.177454, 69.85599] },

        { id: 196, name: 'Point 13', coordinates: [42.392581, 69.782759] },

        { id: 197, name: 'Point 13', coordinates: [42.434326, 69.846621] },
        
        { id: 198, name: 'Point 13', coordinates: [42.269715, 69.850114] },

        { id: 199, name: 'Point 13', coordinates: [42.551764, 69.769813] },

        { id: 200, name: 'Point 13', coordinates: [42.444146, 69.816504] },

        { id: 201, name: 'Point 13', coordinates: [42.464022, 69.749887] },

        { id: 202, name: 'Point 13', coordinates: [42.440373, 69.784605] },

        { id: 203, name: 'Point 13', coordinates: [42.355904, 69.41762] },

        { id: 204, name: 'Point 13', coordinates: [42.337037, 69.76258] },
        { id: 205, name: 'Point 13', coordinates: [42.519866, 69.790537] },
        { id: 206, name: 'Point 13', coordinates: [42.406552, 69.78713] },
        { id: 207, name: 'Point 13', coordinates: [42.421027, 69.772646] },
        { id: 208, name: 'Point 13', coordinates: [42.537398, 69.782122] },
        { id: 209, name: 'Point 13', coordinates: [42.170324, 69.884287] },
        { id: 210, name: 'Point 13', coordinates: [42.193633, 69.818209] },
        { id: 211, name: 'Point 13', coordinates: [42.505261, 69.71001] },
        { id: 212, name: 'Point 13', coordinates: [42.488999, 69.733471] },
        { id: 213, name: 'Point 13', coordinates: [42.2109436, 69.5240043] },
        { id: 214, name: 'Point 13', coordinates: [42.350324, 69.661859] },
        { id: 215, name: 'Point 13', coordinates: [42.315119, 69.784292] },
        { id: 216, name: 'Point 13', coordinates: [42.311902, 69.769795] },
        { id: 217, name: 'Point 13', coordinates: [42.447417, 69.839348] },
        { id: 218, name: 'Point 13', coordinates: [42.365255, 69.732979] },
        { id: 219, name: 'Point 13', coordinates: [42.277197, 69.608858] },
        { id: 220, name: 'Point 13', coordinates: [42.416512, 69.551372] },
        { id: 221, name: 'Point 13', coordinates: [42.3664957, 69.4139716] },
        { id: 222, name: 'Point 13', coordinates: [42.395181, 69.681747] },
        { id: 332, name: 'Point 13', coordinates: [42.32242955050825, 69.5777855800838] },
        { id: 333, name: 'Point 13', coordinates: [42.32583270843056, 69.62783445640608] },
        { id: 334, name: 'Point 13', coordinates: [42.254174441671445, 69.73559796968173] },
        { id: 335, name: 'Point 13', coordinates: [42.35378090030465, 69.70066715310475] },
        { id: 336, name: 'Point 13', coordinates: [42.30441920272266, 69.61589478193787] },
        { id: 337, name: 'Point 13', coordinates: [42.326460672310546, 69.63033785629116] },
        { id: 338, name: 'Point 13', coordinates: [42.313489710218185, 69.6071555665926] },
        { id: 339, name: 'Point 13', coordinates: [42.34192486149116, 69.67241160893298] },
        { id: 340, name: 'Point 13', coordinates: [42.307197460874896, 69.57189995310156] },
        { id: 341, name: 'Point 13', coordinates: [42.3226607592658, 69.61591334404622] },
        { id: 342, name: 'Point 13', coordinates: [42.288966844306124, 69.60379587038804] },
        { id: 343, name: 'Point 13', coordinates: [42.30608627541849, 69.58046082612034] },
        { id: 344, name: 'Point 13', coordinates: [42.33603627817351, 69.64169586828564] },
        { id: 345, name: 'Point 13', coordinates: [42.40130590889399, 69.57304937934303] },
        { id: 346, name: 'Point 13', coordinates: [42.30483510666677, 69.57461684703776] },
        { id: 347, name: 'Point 13', coordinates: [42.32561432588398, 69.63532919542988] },
        { id: 348, name: 'Point 13', coordinates: [42.30540808995659, 69.58707126794297] },
        { id: 349, name: 'Point 13', coordinates: [42.32524417892552, 69.59079495431158] },
        { id: 350, name: 'Point 13', coordinates: [42.25795228140582, 69.70383766873802] },
        { id: 351, name: 'Point 13', coordinates: [42.30434276282473, 69.57445856814773] },
        { id: 352, name: 'Point 13', coordinates: [42.316570687083356, 69.61257540932921] },
        { id: 353, name: 'Point 13', coordinates: [42.3222203620679, 69.585732886584] },
        { id: 354, name: 'Point 13', coordinates: [42.33062664879661, 69.59733936659374] },
        { id: 355, name: 'Point 13', coordinates: [42.33665041819134, 69.64188731285053] },
        { id: 356, name: 'Point 13', coordinates: [42.32177912323339, 69.5740835455288] },
        { id: 357, name: 'Point 13', coordinates: [42.42316013178741, 69.63757549573495] },
        { id: 358, name: 'Point 13', coordinates: [42.25808326234622, 69.70349224029644] },
        { id: 359, name: 'Point 13', coordinates: [42.32550740027528, 69.8670834986315] },
        { id: 360, name: 'Point 13', coordinates: [42.297156686184465, 69.59055119542792] },
        { id: 361, name: 'Point 13', coordinates: [42.31868583020037, 69.6252930242659] },
        { id: 362, name: 'Point 13', coordinates: [42.24854227705978, 69.64121083657253] },
        { id: 363, name: 'Point 13', coordinates: [42.31751265353188, 69.67670998211528] },
        { id: 364, name: 'Point 13', coordinates: [42.337042519122214, 69.56689830769082] },
        { id: 365, name: 'Point 13', coordinates: [42.34423006187631, 69.6217377172148] },
        { id: 366, name: 'Point 13', coordinates: [42.31896871453645, 69.64069068463385] },
        { id: 367, name: 'Point 13', coordinates: [42.36419701635166, 69.66602580595979] },
        { id: 368, name: 'Point 13', coordinates: [42.34508133013827, 69.61493585310414] },
        { id: 369, name: 'Point 13', coordinates: [42.33997967460445, 69.63231707663826] },
        { id: 370, name: 'Point 13', coordinates: [42.328258403290974, 69.55073601077596] },
        { id: 371, name: 'Point 13', coordinates: [42.41464683109075, 69.58617195640487] },
        { id: 372, name: 'Point 13', coordinates: [42.31294140246954, 69.59891971869949] },
        { id: 373, name: 'Point 13', coordinates: [42.345401, 69.521782] },
        { id: 374, name: 'Point 13', coordinates: [42.330842207604654, 69.5287817138316] },
        { id: 375, name: 'Point 13', coordinates: [42.33420805600312, 69.52159262962698] },
        { id: 376, name: 'Point 13', coordinates: [42.31982327256252, 69.5167723070647] },
        { id: 377, name: 'Point 13', coordinates: [42.331290832554224, 69.56256760227019] },
        { id: 378, name: 'Point 13', coordinates: [42.288997848786124, 69.6037317597961] },
        { id: 379, name: 'Point 13', coordinates: [42.372103827922835, 69.37887425639519] },

        { id: 381, name: 'Point 13', coordinates: [42.327698, 69.532042] },
        { id: 382, name: 'Point 13', coordinates: [42.336600, 69.567712] },
        { id: 383, name: 'Point 13', coordinates: [42.354474, 69.558895] },
        { id: 384, name: 'Point 13', coordinates: [42.326716, 69.543525] },
        { id: 385, name: 'Point 13', coordinates: [42.321974, 69.544230] },
        { id: 386, name: 'Point 13', coordinates: [42.317748, 69.546381] },
        { id: 387, name: 'Point 13', coordinates: [42.317926, 69.553573] },
        { id: 388, name: 'Point 13', coordinates: [42.324910, 69.566812] },


        { id: 494, name: 'Point 13', coordinates: [42.312845, 69.581518] },
        { id: 495, name: 'Point 13', coordinates: [42.327416, 69.570370] },
        { id: 496, name: 'Point 13', coordinates: [42.328971, 69.565507] },
        { id: 497, name: 'Point 13', coordinates: [42.330600, 69.561257] },
        { id: 498, name: 'Point 13', coordinates: [42.328244, 69.552553] },
        { id: 499, name: 'Point 13', coordinates: [42.330351, 69.549793] },
        { id: 500, name: 'Point 13', coordinates: [42.330351, 69.549793] },
        { id: 501, name: 'Point 13', coordinates: [ 42.330325, 69.549746] },
        { id: 502, name: 'Point 13', coordinates: [42.331466, 69.552865] },
        { id: 503, name: 'Point 13', coordinates: [42.328246, 69.552558] },
        { id: 504, name: 'Point 13', coordinates: [42.330350, 69.549771] },
        { id: 505, name: 'Point 13', coordinates: [ 42.331464, 69.552861] },
        { id: 506, name: 'Point 13', coordinates: [42.349707, 69.535096] },
        { id: 507, name: 'Point 13', coordinates: [ 42.341906, 69.541059] },
        { id: 508, name: 'Point 13', coordinates: [42.338754, 69.546894] },
        { id: 509, name: 'Point 13', coordinates: [42.339868, 69.553289] },
        { id: 510, name: 'Point 13', coordinates: [42.342002, 69.555993] },
        { id: 511, name: 'Point 13', coordinates: [42.344865, 69.559467] },
        { id: 512, name: 'Point 13', coordinates: [42.341525, 69.566550] },
        { id: 513, name: 'Point 13', coordinates: [42.329809, 69.536040] },
        { id: 514, name: 'Point 13', coordinates: [42.334487, 69.536425] },
        { id: 515, name: 'Point 13', coordinates: [42.336429, 69.531618] },
        { id: 516, name: 'Point 13', coordinates: [42.333215, 69.523035] },
        { id: 517, name: 'Point 13', coordinates: [42.338913, 69.512135] },
        { id: 518, name: 'Point 13', coordinates: [42.340407, 69.524925] },
        { id: 519, name: 'Point 13', coordinates: [42.348559, 69.521102] },
        { id: 520, name: 'Point 13', coordinates: [42.345186, 69.534837] },
        { id: 521, name: 'Point 13', coordinates: [42.347667, 69.528055] },
        { id: 522, name: 'Point 13', coordinates: [42.376472, 69.579167] },
        { id: 523, name: 'Point 13', coordinates: [42.372556, 69.542260] },
        { id: 524, name: 'Point 13', coordinates: [42.354957, 69.529472] },
        { id: 525, name: 'Point 13', coordinates: [42.358012, 69.513077] },
        { id: 526, name: 'Point 13', coordinates: [42.368707, 69.511791] },
        { id: 527, name: 'Point 13', coordinates: [42.366416, 69.522607] },
        { id: 528, name: 'Point 13', coordinates: [42.352284, 69.534795] },
        { id: 529, name: 'Point 13', coordinates: [42.360688, 69.540035] },
        { id: 530, name: 'Point 13', coordinates: [42.362343, 69.558397] },

        { id: 531, name: 'Point 13', coordinates: [42.368264, 69.551446] },
        { id: 532, name: 'Point 13', coordinates: [42.381498, 69.547499] },
        { id: 533, name: 'Point 13', coordinates: [42.376153, 69.555738] },
        { id: 534, name: 'Point 13', coordinates: [42.371570, 69.570843] },
        { id: 535, name: 'Point 13', coordinates: [42.380738, 69.563891] },
        { id: 536, name: 'Point 13', coordinates: [42.379721, 69.586031] },
        { id: 537, name: 'Point 13', coordinates: [42.379716, 69.586035] },
        { id: 538, name: 'Point 13', coordinates: [42.375583, 69.593931] },
        { id: 539, name: 'Point 13', coordinates: [42.380662, 69.599057] },
        { id: 540, name: 'Point 13', coordinates: [42.383914, 69.598050] },
        { id: 541, name: 'Point 13', coordinates: [42.385251, 69.581228] },
        { id: 542, name: 'Point 13', coordinates: [42.388309, 69.569468] },
        { id: 543, name: 'Point 13', coordinates: [42.401731, 69.598266] },
        { id: 544, name: 'Point 13', coordinates: [42.405486, 69.606203] },
        { id: 545, name: 'Point 13', coordinates: [42.404562, 69.637578] },
        { id: 546, name: 'Point 13', coordinates: [42.408887, 69.619210] },
        { id: 547, name: 'Point 13', coordinates: [42.412705, 69.617324] },
        { id: 548, name: 'Point 13', coordinates: [42.422369, 69.568223] },
        { id: 549, name: 'Point 13', coordinates: [42.425170, 69.577495] },
        { id: 550, name: 'Point 13', coordinates: [42.410163, 69.591746] },
        { id: 551, name: 'Point 13', coordinates: [42.311252, 69.579351] },
        { id: 552, name: 'Point 13', coordinates: [42.286753, 69.606288] },
        { id: 553, name: 'Point 13', coordinates: [42.286406, 69.573199] },
        { id: 554, name: 'Point 13', coordinates: [42.296723, 69.575690] },
        { id: 555, name: 'Point 13', coordinates: [42.294849, 69.571742] },
        { id: 556, name: 'Point 13', coordinates: [42.300042, 69.566938] },
        { id: 557, name: 'Point 13', coordinates: [42.293062, 69.542602] },
        { id: 558, name: 'Point 13', coordinates: [42.291821, 69.547410] },
        { id: 559, name: 'Point 13', coordinates: [42.293256, 69.549343] },
        { id: 560, name: 'Point 13', coordinates: [42.297907, 69.557795] },
        { id: 561, name: 'Point 13', coordinates: [42.295040, 69.554361] },
        { id: 562, name: 'Point 13', coordinates: [42.288030, 69.553763] },
        { id: 563, name: 'Point 13', coordinates: [42.291469, 69.555008] },
        { id: 564, name: 'Point 13', coordinates: [42.291695, 69.560499] },
        { id: 565, name: 'Point 13', coordinates: [42.286662, 69.561144] },
        { id: 566, name: 'Point 13', coordinates: [42.305330, 69.616946] },
        { id: 567, name: 'Point 13', coordinates: [42.304503, 69.616430] },
        { id: 568, name: 'Point 13', coordinates: [42.308864, 69.612014] },
        { id: 569, name: 'Point 13', coordinates: [42.300074, 69.544063] },

        { id: 570, name: 'Point 13', coordinates: [42.307591, 69.584204] },
        { id: 571, name: 'Point 13', coordinates: [42.310490, 69.585741] },
        { id: 572, name: 'Point 13', coordinates: [42.304723, 69.591062] },
        { id: 573, name: 'Point 13', coordinates: [42.303035, 69.594243] },
        { id: 574, name: 'Point 13', coordinates: [42.302844, 69.598706] },
        { id: 575, name: 'Point 13', coordinates: [42.346309, 69.663209] },
        { id: 576, name: 'Point 13', coordinates: [42.342944, 69.664242] },
        { id: 577, name: 'Point 13', coordinates: [42.339052, 69.657628] },
        { id: 578, name: 'Point 13', coordinates: [42.338865, 69.651874] },
        { id: 579, name: 'Point 13', coordinates: [42.351663, 69.572143] },
        { id: 580, name: 'Point 13', coordinates: [42.353258, 69.577896] },
        { id: 581, name: 'Point 13', coordinates: [42.344090, 69.579867] },
        { id: 582, name: 'Point 13', coordinates: [42.342240, 69.578499] },
        { id: 583, name: 'Point 13', coordinates: [42.341663, 69.585184] },
        { id: 584, name: 'Point 13', coordinates: [42.336961, 69.579701] },
        { id: 585, name: 'Point 13', coordinates: [42.334599, 69.579435] },
        { id: 580, name: 'Point 13', coordinates: [42.331667, 69.581755] },
        { id: 591, name: 'Point 13', coordinates: [42.330523, 69.578233] },
        { id: 592, name: 'Point 13', coordinates: [42.330204, 69.592997] },
        { id: 593, name: 'Point 13', coordinates: [42.329791, 69.598919] },
        { id: 594, name: 'Point 13', coordinates: [42.327082, 69.597545] },
        { id: 595, name: 'Point 13', coordinates: [42.325427, 69.597715] },
        { id: 596, name: 'Point 13', coordinates: [42.325427, 69.597715] },
        { id: 597, name: 'Point 13', coordinates: [42.323896, 69.600723] },
        { id: 598, name: 'Point 13', coordinates: [42.322941, 69.606216] },
        { id: 599, name: 'Point 13', coordinates: [42.330841, 69.605272] },
        { id: 600, name: 'Point 13', coordinates: [42.328740, 69.607034] },
        { id: 601, name: 'Point 13', coordinates: [42.328199, 69.612657] },
        { id: 602, name: 'Point 13', coordinates: [42.329407, 69.617636] },
        { id: 603, name: 'Point 13', coordinates: [42.325778, 69.617373] },
        { id: 604, name: 'Point 13', coordinates: [42.322880, 69.618533] },
        { id: 605, name: 'Point 13', coordinates: [42.328038, 69.644150] },
        { id: 606, name: 'Point 13', coordinates: [42.326320, 69.645484] },
        { id: 607, name: 'Point 13', coordinates: [42.332467, 69.640506] },
        { id: 608, name: 'Point 13', coordinates: [42.331445, 69.638274] },
        { id: 609, name: 'Point 13', coordinates: [42.325077, 69.636602] },
        { id: 610, name: 'Point 13', coordinates: [42.325077, 69.636602] },
        { id: 611, name: 'Point 13', coordinates: [42.325077, 69.636602] },
        { id: 612, name: 'Point 13', coordinates: [42.323485, 69.641019] },
        { id: 613, name: 'Point 13', coordinates: [42.321479, 69.635487] },
        { id: 614, name: 'Point 13', coordinates: [42.320589, 69.636944] },
        { id: 615, name: 'Point 13', coordinates: [42.336251, 69.640675] },
        { id: 616, name: 'Point 13', coordinates: [42.335840, 69.637460] },
        { id: 617, name: 'Point 13', coordinates: [42.338197, 69.637974] },
        { id: 618, name: 'Point 13', coordinates: [42.315396, 69.664924] },
        { id: 619, name: 'Point 13', coordinates: [42.313487, 69.662780] },
        { id: 620, name: 'Point 13', coordinates: [42.318295, 69.652179] },
        { id: 621, name: 'Point 13', coordinates: [42.317402, 69.650804] },
        { id: 622, name: 'Point 13', coordinates: [42.310807, 69.654193] },
        { id: 623, name: 'Point 13', coordinates: [42.311955, 69.645953] },
        { id: 624, name: 'Point 13', coordinates: [42.306923, 69.642438] },
        { id: 625, name: 'Point 13', coordinates: [42.305647, 69.651276] },
        { id: 626, name: 'Point 13', coordinates: [42.303802, 69.646472] },
        { id: 627, name: 'Point 13', coordinates: [42.304755, 69.638746] },
        { id: 628, name: 'Point 13', coordinates: [42.299277, 69.634625] },
        { id: 629, name: 'Point 13', coordinates: [42.295328, 69.640637] },
        { id: 630, name: 'Point 13', coordinates: [42.297620, 69.622953] },
        { id: 631, name: 'Point 13', coordinates: [42.294944, 69.614968] },
        { id: 632, name: 'Point 13', coordinates: [42.297619, 69.606300] },
        { id: 633, name: 'Point 13', coordinates: [42.299720, 69.594797] },
        { id: 634, name: 'Point 13', coordinates: [42.295199, 69.591023] },
        { id: 635, name: 'Point 13', coordinates: [42.300105, 69.585790] },
        { id: 636, name: 'Point 13', coordinates: [42.293925, 69.579005] },
        { id: 637, name: 'Point 13', coordinates: [42.300295, 69.577205] },
        { id: 638, name: 'Point 13', coordinates: [42.301889, 69.571024] },
        { id: 639, name: 'Point 13', coordinates: [42.304630, 69.574027] },
        { id: 640, name: 'Point 13', coordinates: [42.306478, 69.572915] },
        { id: 641, name: 'Point 13', coordinates: [42.307301, 69.577463] },
        { id: 642, name: 'Point 13', coordinates: [42.309503, 69.578622] },
        { id: 643, name: 'Point 13', coordinates: [42.305905, 69.603340] },

        { id: 644, name: 'Point 13', coordinates: [42.361593, 69.687762] },
        { id: 645, name: 'Point 13', coordinates: [42.387609, 69.626299] },
        { id: 646, name: 'Point 13', coordinates: [42.374238, 69.645254] },
        { id: 647, name: 'Point 13', coordinates: [42.379812, 69.641987] },
        { id: 648, name: 'Point 13', coordinates: [42.384356, 69.638675] },
        { id: 649, name: 'Point 13', coordinates: [42.377233, 69.656745] },
        { id: 650, name: 'Point 13', coordinates: [42.382204, 69.662072] },
        { id: 651, name: 'Point 13', coordinates: [42.382741, 69.653150] },
        { id: 652, name: 'Point 13', coordinates: [42.378442, 69.676438] },
        { id: 653, name: 'Point 13', coordinates: [42.373639, 69.633020] },
        { id: 654, name: 'Point 13', coordinates: [42.369341, 69.645799] },
        { id: 655, name: 'Point 13', coordinates: [42.371479, 69.652885] },
        { id: 656, name: 'Point 13', coordinates: [42.371161, 69.658757] },
        { id: 657, name: 'Point 13', coordinates: [42.373444, 69.660534] },
        { id: 658, name: 'Point 13', coordinates: [42.369433, 69.677302] },
        { id: 659, name: 'Point 13', coordinates: [42.366256, 69.666010] },
        { id: 660, name: 'Point 13', coordinates: [42.362865, 69.648258] },
        { id: 661, name: 'Point 13', coordinates: [42.355389, 69.662308] },
        { id: 662, name: 'Point 13', coordinates: [42.378016, 69.617204] },
        { id: 663, name: 'Point 13', coordinates: [42.376994, 69.601923] },
        { id: 664, name: 'Point 13', coordinates: [42.361981, 69.600629] },
        { id: 665, name: 'Point 13', coordinates: [42.364718, 69.606215] },
        { id: 666, name: 'Point 13', coordinates: [42.358918, 69.605094] },
        { id: 667, name: 'Point 13', coordinates: [42.357649, 69.605879] },
        { id: 668, name: 'Point 13', coordinates: [42.357138, 69.616601] },
        { id: 669, name: 'Point 13', coordinates: [42.354016, 69.612993] },
        { id: 670, name: 'Point 13', coordinates: [42.354908, 69.626984] },
        { id: 671, name: 'Point 13', coordinates: [42.366304, 69.626729] },
        { id: 672, name: 'Point 13', coordinates: [42.363947, 69.624585] },
        { id: 673, name: 'Point 13', coordinates: [42.363245, 69.632310] },
        { id: 674, name: 'Point 13', coordinates: [42.359556, 69.623989] },
        { id: 675, name: 'Point 13', coordinates: [42.357770, 69.629134] },
        { id: 676, name: 'Point 13', coordinates: [42.355290, 69.637195] },
        { id: 677, name: 'Point 13', coordinates: [42.359367, 69.642953] },
        { id: 678, name: 'Point 13', coordinates: [42.354720, 69.652952] },
        { id: 679, name: 'Point 13', coordinates: [42.359463, 69.636189] },
        { id: 680, name: 'Point 13', coordinates: [42.351472, 69.651096] },
        { id: 681, name: 'Point 13', coordinates: [42.347114, 69.608253] },
        { id: 682, name: 'Point 13', coordinates: [42.344058, 69.609710] },
        { id: 683, name: 'Point 13', coordinates: [42.346595, 69.611775] },
        { id: 684, name: 'Point 13', coordinates: [42.344022, 69.612891] },
        { id: 685, name: 'Point 13', coordinates: [42.345620, 69.612159] },
        { id: 686, name: 'Point 13', coordinates: [42.346185, 69.614555] },
        { id: 687, name: 'Point 13', coordinates: [42.345236, 69.615292] },
        { id: 688, name: 'Point 13', coordinates: [42.345200, 69.607526] },
        { id: 689, name: 'Point 13', coordinates: [42.343414, 69.624950] },
        { id: 690, name: 'Point 13', coordinates: [42.342751, 69.626833] },
        { id: 691, name: 'Point 13', coordinates: [42.342402, 69.628767] },
        { id: 692, name: 'Point 13', coordinates: [42.342843, 69.631905] },
        { id: 693, name: 'Point 13', coordinates: [42.345777, 69.627126] },
        { id: 694, name: 'Point 13', coordinates: [42.344804, 69.636172] },
        { id: 695, name: 'Point 13', coordinates: [42.342063, 69.640030] },
        { id: 696, name: 'Point 13', coordinates: [42.341875, 69.644066] },
        { id: 697, name: 'Point 13', coordinates: [42.342956, 69.648100] },
        { id: 698, name: 'Point 13', coordinates: [42.342367, 69.677555] },
        { id: 699, name: 'Point 13', coordinates: [42.351217, 69.677535] },
        { id: 700, name: 'Point 13', coordinates: [42.345298, 69.685010] },
        { id: 701, name: 'Point 13', coordinates: [42.351597, 69.696766] },
        { id: 702, name: 'Point 13', coordinates: [42.353123, 69.699513] },
        { id: 703, name: 'Point 13', coordinates: [42.356565, 69.713761] },
        { id: 704, name: 'Point 13', coordinates: [42.362734, 69.704322] },
        { id: 705, name: 'Point 13', coordinates: [42.369029, 69.709295] },
        { id: 706, name: 'Point 13', coordinates: [42.375845, 69.701318] },
        { id: 707, name: 'Point 13', coordinates: [42.359819, 69.693419] },
        

 

        // Другие точки оператора 2...

        // Қар-Тел БС *******************************************************************************************************
      ],
       // Замените на путь к иконке маркера оператора 2
      pointIcon: icon2, // Замените на путь к иконке точки оператора 2
    },
    {
        id: 3,
        name: 'Operator 3',
        points: [
          { id: 223, name: 'Point 13', coordinates: [42.435932, 69.637777] },
          { id: 224, name: 'Point 13', coordinates: [42.288802, 69.782876] },
          { id: 225, name: 'Point 13', coordinates: [42.288802, 69.781576] },
          { id: 226, name: 'Point 13', coordinates: [42.433862, 69.637228] },
          { id: 227, name: 'Point 13', coordinates: [42.429240, 69.644233] },
          { id: 228, name: 'Point 13', coordinates: [42.425024, 69.637228] },
          { id: 229, name: 'Point 13', coordinates: [42.430132, 69.625189] },
          { id: 230, name: 'Point 13', coordinates: [42.425916, 69.613259] },
          { id: 231, name: 'Point 13', coordinates: [42.422315, 69.579314] },
          { id: 232, name: 'Point 13', coordinates: [42.416720, 69.596388] },
          { id: 233, name: 'Point 13', coordinates: [42.405947, 69.657744] },
          { id: 234, name: 'Point 13', coordinates: [42.403538, 69.644897] },
          { id: 235, name: 'Point 13', coordinates: [42.397449, 69.648432] },
          { id: 236, name: 'Point 13', coordinates: [42.402387, 69.551133] },
          { id: 237, name: 'Point 13', coordinates: [42.402524, 69.581320] },
          { id: 238, name: 'Point 13', coordinates: [42.398840, 69.577455] },
          { id: 239, name: 'Point 13', coordinates: [42.384705, 69.493224] },
          { id: 240, name: 'Point 13', coordinates: [42.365013, 69.667002] },
          { id: 241, name: 'Point 13', coordinates: [42.382343, 69.714428] },
          { id: 242, name: 'Point 13', coordinates: [42.377539, 69.759573] },
          { id: 243, name: 'Point 13', coordinates: [42.359977, 69.617849] },
          { id: 244, name: 'Point 13', coordinates: [42.353970, 69.636893] },
          { id: 245, name: 'Point 13', coordinates: [42.346964, 69.605129] },
          { id: 246, name: 'Point 13', coordinates: [42.333442, 69.599562] },
          { id: 247, name: 'Point 13', coordinates: [42.329917, 69.624097] },
          { id: 248, name: 'Point 13', coordinates: [42.328554, 69.585776] },
          { id: 249, name: 'Point 13', coordinates: [42.310811, 69.618486] },
          { id: 250, name: 'Point 13', coordinates: [42.308049, 69.612302] },
          { id: 251, name: 'Point 13', coordinates: [42.292958, 69.656463] },
          { id: 252, name: 'Point 13', coordinates: [42.281700, 69.665582] },
          { id: 253, name: 'Point 13', coordinates: [42.280907, 69.633370] },
          { id: 254, name: 'Point 13', coordinates: [42.270245, 69.590482] },
          { id: 255, name: 'Point 13', coordinates: [42.284160, 69.540934] },
          { id: 256, name: 'Point 13', coordinates: [42.234006, 69.638896] },
          { id: 481, name: 'Point 13', coordinates: [42.309028, 69.749883] },
          { id: 482, name: 'Point 13', coordinates: [42.379100, 69.713957] },
          { id: 483, name: 'Point 13', coordinates: [42.415810, 69.682631] },
          { id: 484, name: 'Point 13', coordinates: [42.349859, 69.469469] },
          { id: 485, name: 'Point 13', coordinates: [42.350689, 69.470657] },
          { id: 486, name: 'Point 13', coordinates: [42.358587, 69.451046] },
          { id: 487, name: 'Point 13', coordinates: [42.229564, 69.498393] },
          { id: 488, name: 'Point 13', coordinates: [42.230816, 69.637558] },
          { id: 489, name: 'Point 13', coordinates: [42.258239, 69.625842] },
          { id: 490, name: 'Point 13', coordinates: [42.375875, 69.759438] },
          { id: 491, name: 'Point 13', coordinates: [42.428186, 69.625308] },
          { id: 492, name: 'Point 13', coordinates: [42.424172, 69.613307] },
          { id: 493, name: 'Point 13', coordinates: [42.420625, 69.578824] },
          
          // Другие точки оператора 1...
        ],
        pointIcon: icon3 // Замените на путь к иконке точки оператора 1
      },
    // Другие операторы...

    //AMC *********************************************************************************************************************************

    ///////////// AMC Желтый //////////////////////////////////////////////////////
    {
      id: 4,
      name: 'Operator 4',
      points: [
       
        { id: 708, name: 'Point 13', coordinates: [42.2895190,69.6741040] },
        { id: 709, name: 'Point 13', coordinates: [42.256733, 69.576154] },
        { id: 710, name: 'Point 13', coordinates: [42.4226585334173, 69.64093658958653] },
        { id: 711, name: 'Point 13', coordinates: [42.4239100,69.5627480] },
        { id: 712, name: 'Point 13', coordinates: [42.4107120,69.5321590] },
        { id: 714, name: 'Point 13', coordinates: [42.2132600,69.5274530] },
        { id: 715, name: 'Point 13', coordinates: [42.3544150,69.6039180] },
        { id: 716, name: 'Point 13', coordinates: [42.395903, 69.564684] },

        // Другие точки оператора 1...
      ],
      pointIcon: iconYellow // Замените на путь к иконке точки оператора 1
    },
/////////////////////// AMC Красный /////////////////////////////////////////////////
    {
      id: 4,
      name: 'Operator 4',
      points: [
        { id: 748, name: 'Point 13', coordinates: [42.3538740,69.5142580] },
        { id: 749, name: 'Point 13', coordinates: [42.2664260,69.6297610] },
        { id: 751, name: 'Point 13', coordinates: [42.3369810,69.6743170] },
        { id: 752, name: 'Point 13', coordinates: [42.3537560,69.6840990] },
        { id: 753, name: 'Point 13', coordinates: [42.3330560,69.6607550] },
        { id: 754, name: 'Point 13', coordinates: [42.341472, 69.654167] },
      ],
      pointIcon: iconRed
    },

////////////////////// AMC Зеленый //////////////////////////////////////////////////
    {
      id: 4,
      name: 'Operator 4',
      points: [
        { id: 712, name: 'Point 13', coordinates: [42.2901460,69.5135650] },
        { id: 714, name: 'Point 13', coordinates: [42.2731570,69.5742230] },
        { id: 7155, name: 'Point 13', coordinates: [42.3098520,69.5142440] },
        { id: 7166, name: 'Point 13', coordinates: [42.4506030,69.5217880] },
        { id: 7177, name: 'Point 13', coordinates: [42.3781940,69.5086040] },
        { id: 7188, name: 'Point 13', coordinates: [42.2750730,69.6277280] },
        { id: 7199, name: 'Point 13', coordinates: [42.4361570,69.5545610] },
        { id: 71110, name: 'Point 13', coordinates: [42.4323740,69.5296830] },
        { id: 7111, name: 'Point 13', coordinates: [42.3828338,69.5315898] },
        { id: 7112, name: 'Point 13', coordinates: [42.26267930583644, 69.57906536813691] },
        { id: 7113, name: 'Point 13', coordinates: [42.4604820,69.5496560] },
        { id: 7114, name: 'Point 13', coordinates: [42.4616450,69.5145330] },
        { id: 7115, name: 'Point 13', coordinates: [42.3494530,69.4639920] },
        { id: 7116, name: 'Point 13', coordinates: [42.4035980,69.6471710] },
        { id: 7117, name: 'Point 13', coordinates: [42.43303326693435, 69.63452204489865] },
        { id: 7118, name: 'Point 13', coordinates: [42.4103850,69.5826300] },
        { id: 7119, name: 'Point 13', coordinates: [42.3946196,69.6119593] },
        { id: 7120, name: 'Point 13', coordinates: [42.3796383,69.7637596] },
        { id: 7121, name: 'Point 13', coordinates: [42.254683, 69.722178] },
        { id: 7122, name: 'Point 13', coordinates: [42.368993, 69.530215] },
        { id: 7123, name: 'Point 13', coordinates: [42.399833, 69.655556] },
        { id: 7125, name: 'Point 13', coordinates: [42.319250, 69.681778] },
        { id: 7126, name: 'Point 13', coordinates: [42.382306, 69.503083] },
        { id: 7127, name: 'Point 13', coordinates: [42.340278, 69.494694] },
        { id: 7128, name: 'Point 13', coordinates: [42.415152, 69.549717] },
        { id: 7129, name: 'Point 13', coordinates: [42.231949, 69.506200] },
        { id: 7130, name: 'Point 13', coordinates: [42.193417, 69.519806] },
        { id: 7131, name: 'Point 13', coordinates: [42.387972, 69.591583] },
        { id: 7132, name: 'Point 13', coordinates: [42.3512260,69.4285320] },
        
      ],
      pointIcon: iconGreen
    },

    {
      id: 5,
      name: 'Operator 5',
      points: [
        { id: 259, name: 'Point 13', coordinates: [42.368467, 69.622452] },
        { id: 260, name: 'Point 13', coordinates: [42.368498, 69.619611] },
        { id: 261, name: 'Point 13', coordinates: [42.369184, 69.618229] },
        { id: 262, name: 'Point 13', coordinates: [42.369756, 69.616690] },
        { id: 263, name: 'Point 13', coordinates: [42.370086, 69.616141] },
        { id: 264, name: 'Point 13', coordinates: [42.368187, 69.621332] },
        { id: 265, name: 'Point 13', coordinates: [42.387555, 69.627446] },
        { id: 266, name: 'Point 13', coordinates: [42.387251, 69.627129] },
        { id: 267, name: 'Point 13', coordinates: [42.384895, 69.627532] },
        { id: 268, name: 'Point 13', coordinates: [42.384684, 69.626292] },
        { id: 269, name: 'Point 13', coordinates: [42.384802, 69.625831] },
        { id: 270, name: 'Point 13', coordinates: [42.385398, 69.627590] },
        { id: 271, name: 'Point 13', coordinates: [42.363681, 69.621673] },
        { id: 272, name: 'Point 13', coordinates: [42.364453, 69.622247] },
        { id: 273, name: 'Point 13', coordinates: [42.382624, 69.626706] },
        { id: 274, name: 'Point 13', coordinates: [42.382572, 69.628291] },
        { id: 275, name: 'Point 13', coordinates: [42.353050, 69.653833] },
        { id: 276, name: 'Point 13', coordinates: [42.353384, 69.652796] },
        { id: 277, name: 'Point 13', coordinates: [42.313208, 69.603350] },
        { id: 278, name: 'Point 13', coordinates: [42.312343, 69.603675] },
        { id: 279, name: 'Point 13', coordinates: [42.316115, 69.586907] },
        { id: 280, name: 'Point 13', coordinates: [42.316713, 69.586068] },
        { id: 281, name: 'Point 13', coordinates: [42.317262, 69.585321] },
        { id: 282, name: 'Point 13', coordinates: [42.317893, 69.584862] },
        { id: 283, name: 'Point 13', coordinates: [42.3562, 69.642939] },
        { id: 284, name: 'Point 13', coordinates: [42.356387, 69.642303] },
        { id: 285, name: 'Point 13', coordinates: [42.356658, 69.641436] },
        { id: 286, name: 'Point 13', coordinates: [42.356806, 69.640922] },
        { id: 287, name: 'Point 13', coordinates: [42.320967, 69.588396] },
        { id: 288, name: 'Point 13', coordinates: [42.320777, 69.588989] },
        { id: 289, name: 'Point 13', coordinates: [42.316966, 69.609838] },
        { id: 290, name: 'Point 13', coordinates: [42.316526, 69.611778] },
        { id: 291, name: 'Point 13', coordinates: [42.315614, 69.615520] },
        { id: 292, name: 'Point 13', coordinates: [42.316329, 69.612322] },
        { id: 293, name: 'Point 13', coordinates: [42.410001, 69.598731] },
        { id: 294, name: 'Point 13', coordinates: [42.410488, 69.598412] },
        { id: 295, name: 'Point 13', coordinates: [42.321241, 69.579156] },
        { id: 296, name: 'Point 13', coordinates: [42.320844, 69.580382] },
        { id: 297, name: 'Point 13', coordinates: [42.320519, 69.581269] },
        { id: 298, name: 'Point 13', coordinates: [42.321406, 69.588006] },
        { id: 299, name: 'Point 13', coordinates: [42.321930, 69.587762] },
        { id: 750, name: 'Point 13', coordinates: [42.335950, 69.599572] },
        { id: 751, name: 'Point 13', coordinates: [42.336132, 69.598670] },
        { id: 752, name: 'Point 13', coordinates: [42.362386, 69.622876] },
        { id: 753, name: 'Point 13', coordinates: [42.361229, 69.625968] },
        { id: 754, name: 'Point 13', coordinates: [42.361797, 69.624408] },
        { id: 755, name: 'Point 13', coordinates: [42.360961, 69.62648] },
        { id: 756, name: 'Point 13', coordinates: [42.366985, 69.49358] },
        { id: 757, name: 'Point 13', coordinates: [42.366813, 69.494189] },
        { id: 758, name: 'Point 13', coordinates: [42.337293, 69.593262] },
        { id: 759, name: 'Point 13', coordinates: [42.316136, 69.599611] },
        { id: 760, name: 'Point 13', coordinates: [42.315859, 69.598797] },
        { id: 761, name: 'Point 13', coordinates: [42.316094, 69.600755] },
      
      ],
      pointIcon: icon5
    },

    ////////////////////// Beeline ///////////////////////////////////////////////////////////////
    /////////////////////// AMC Красный /////////////////////////////////////////////////
    {
      id: 6,
      name: 'Operator 6',
      points: [
        { id: 762, name: 'Point 13', coordinates: [42.28702, 69.769859   ] }, //Базарқақпа
        { id: 763, name: 'Point 13', coordinates: [42.265494, 69.838480 ] },  //Абдуллабад
        { id: 764, name: 'Point 13', coordinates: [42.387604, 69.626858  ] }, //Памятник Байдыбек Би


      ],
      pointIcon: iconRedBee
    },
    ///////////// AMC Желтый //////////////////////////////////////////////////////
    {
      id: 6,
      name: 'Operator 6',
      points: [
        { id: 765, name: 'Point 13', coordinates: [42.361747, 69.530982 ] },  //Акниет
        { id: 767, name: 'Point 13', coordinates: [42.330480, 69.655061 ] }, //Кабиско
        { id: 768, name: 'Point 13', coordinates: [42.428374, 69.557370] },  //Дача Кайнарбулак
        { id: 769, name: 'Point 13', coordinates: [42.312419, 69.784546 ] }, //Сайрам
        { id: 770, name: 'Point 13', coordinates: [42.256582, 69.575719 ] }, //276-квартал
        { id: 771, name: 'Point 13', coordinates: [42.400713, 69.592538 ] }, //Асар
        { id: 772, name: 'Point 13', coordinates: [42.429708, 69.610698 ] }, //Бозарык
        { id: 773, name: 'Point 13', coordinates: [42.385817, 69.664309  ] }, //Туран
        { id: 774, name: 'Point 13', coordinates: [42.394340, 69.610342  ] }, //Асар
        { id: 775, name: 'Point 13', coordinates: [42.342562, 69.648326  ] }, //Шапағат
        
      
      ],
      pointIcon: iconYellowBee
    },
    ////////////////////// AMC Зеленый //////////////////////////////////////////////////
    {
      id: 6,
      name: 'Operator 6',
      points: [
      
      
      ],
      pointIcon: iconGreenBee
    },
  // Другие операторы...
  ];